export const PAGES_MENU_ID = 'CUSTOM_MAIN_MENU';
// This one is used in verticalMenu.spec.js and goes to list of custom menus
export const STRANGE_MENU_ID = 'CUSTOM_MENU';

export const MENU_SETS_MANAGE_PANEL_NAME = 'menu.panels.MenuSetsPanel';
export const DELETE_MENU_SET_PANEL_NAME = 'menu.panels.DeleteMenuSetPanel';
export const MULTISELECT_PANEL_NAME = 'panels.toolPanels.MultiselectPanel';
export const MENU_SETS_RADIO_MANAGE_PANEL_NAME =
  'menu.panels.MenuSetsRadioPanel';

export const MENU_ITEM_TYPE = 'BasicMenuItem' as const;

export const MAIN_MENU_TYPE = 'MAIN_MENU';

export const menuComponentTypes = {
  dropDown: 'wysiwyg.viewer.components.menus.DropDownMenu',
  vertical: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
  tiny: 'wysiwyg.viewer.components.mobile.TinyMenu',
  expandable: 'wysiwyg.viewer.components.ExpandableMenu',
  stylableHorizontal: 'wixui.StylableHorizontalMenu',
  breadcrumbs: 'wixui.Breadcrumbs',
};

export const MENU_SUPPORTS_SUBSUBITEMS = [
  'wysiwyg.viewer.components.ExpandableMenu',
  'wixui.StylableHorizontalMenu',
  'wixui.Breadcrumbs',
];

export const MENU_COMPONENTS_DONT_SUPPORT_SUBNESTING_EXCUSES = {
  [menuComponentTypes.vertical]: {
    content: 'custom_menu_manage_menu_vertical_sub_sub_item_warning_tooltip',
    linkText:
      'custom_menu_manage_menu_vertical_sub_sub_item_warning_tooltip_link',
    helpId: '67773011-da6c-4ff9-b1c9-1c75a0805adb',
  },
  [menuComponentTypes.tiny]: {
    content: 'custom_menu_manage_menu_mobile_sub_sub_item_warning_tooltip',
    linkText:
      'custom_menu_manage_menu_mobile_sub_sub_item_warning_tooltip_link',
    helpId: '67773011-da6c-4ff9-b1c9-1c75a0805adb',
  },
  [menuComponentTypes.dropDown]: {
    content:
      'custom_menu_manage_menu_horizontal_old_sub_sub_item_warning_tooltip',
    linkText:
      'custom_menu_manage_menu_horizontal_old_sub_sub_item_warning_tooltip_link',
    helpId: '67773011-da6c-4ff9-b1c9-1c75a0805adb',
  },
};

export const MEMBERS_MENU_ID = 'MEMBERS_SUB_MENU';
const MEMBERS_LOGIN_MENU_ID = 'MEMBERS_LOGIN_MENU';
const MEMBERS_LOGIN_ICONS_MENU_ID = 'MEMBERS_LOGIN_ICONS_MENU';

export const MEMBER_MENUS_IDS = [
  MEMBERS_MENU_ID,
  MEMBERS_LOGIN_MENU_ID,
  MEMBERS_LOGIN_ICONS_MENU_ID,
];

export const MEMBER_LOGIN_MENU_IDS = [
  MEMBERS_LOGIN_MENU_ID,
  MEMBERS_LOGIN_ICONS_MENU_ID,
];

export const NON_ATTACHABLE_MENUS_IDS = [...MEMBER_MENUS_IDS, PAGES_MENU_ID];

// TODO CustomMenus: add pages menu id after migration
export const NON_CUSTOM_MENUS_IDS = [
  ...MEMBER_MENUS_IDS,
  PAGES_MENU_ID,
  STRANGE_MENU_ID,
];

export const SHOW_DISABLED_HIDE_ON_PAGES_PANEL = 'showDisabledHideOnPagesPanel';

export const FALLBACK_PAGES_MENU_NAME = 'FALLBACK_PAGES_MENU';
export const FALLBACK_MENU_TYPE = '____falback_migration_menu____';

export const ADVANCED_MENU_TYPE = 'ADVANCED_MENU';
export const MEMBERS_MENU_CONTROLLER = 'members-menu';

export const MENUS_TYPE_BEFORE_ADVANCED_MENUS_MIGRATION_KEY =
  'menusTypeBeforeAdvancedMigration';

export const IS_ADVANCED_MENUS_MIGRATION_PANEL_SHOWN =
  'isAdvancedMigrationPanelShown';

export const MENUS_USAGE_TYPES = {
  NEITHER: 'neither',
  CUSTOM: 'custom',
  CUSTOM_PRO: 'custom_pro',
  ADVANCED: 'advanced',
} as const;

export type SiteMenusType =
  typeof MENUS_USAGE_TYPES[keyof typeof MENUS_USAGE_TYPES];

export const CUSTOM_MENUS_USAGE_TYPES: SiteMenusType[] = [
  MENUS_USAGE_TYPES.CUSTOM,
  MENUS_USAGE_TYPES.CUSTOM_PRO,
];
