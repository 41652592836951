import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import type { CompData } from 'types/documentServices';
import type { CompRef } from '@wix/document-services-types';
import constants from '@/constants';
import {
  getColorValueByRole,
  type ColorName,
  type ColorPalette,
  COLOR_ROLES,
  isTextCompData,
  TEXT_COLOR_CLASS_REGEX,
  extractColorFromClassAttr,
  COLOR_REGEX,
  TEXT_HEX_STYLE_REGEX,
  HEX_REGEX,
  extractHexFromStyleAttr,
  extractTextOrBackgroundColorFromClassAttr,
  ColorMatch,
  TEXT_COLOR_BACKGROUND_COLOR_REGEX,
  getColorNameByRole,
} from '../../../colors';
import {
  type CompType,
  type PathPriorityLists,
  COMPS_WIRING_CONFIG,
  SITE_FONTS,
  type PathPriority,
} from './config/compsWiringConfig';
import {
  replaceColorsInStyleProps,
  wrapColorWithClassAttr,
  updateButtonsColorsInStyleData,
  updateDataInPropertiesSource,
  wrapColorWithClassAtributeByPropertyType,
} from '../utils';
import type { ButtonPathLists } from './config/getButtonPathPriorityLists';
import { getAllMatchesFromStyleProps } from '../../utils';
import experiment from 'experiment';

const { COMP_TYPES } = constants;

export const updateColorsInStyleData = (
  styleData: CompData,
  pathPriorityLists: PathPriorityLists,
  allColors: ColorPalette,
  themeColors: ColorName[],
) => {
  const isTextFlow = isTextCompData(styleData);

  _.forOwn(
    pathPriorityLists,
    (wiringPriorityList: PathPriority, path: string) => {
      let currentPathValue = _.get(styleData, path);

      if (!currentPathValue || typeof currentPathValue === 'number') {
        return;
      }

      // for the cases when priority list depends on content
      if (typeof wiringPriorityList === 'function') {
        wiringPriorityList = wiringPriorityList(currentPathValue);
      }
      const matchToHexMappers = [
        experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')
          ? {
              getHex: (c: string) => allColors[c as ColorName],
              regex: isTextFlow
                ? TEXT_COLOR_BACKGROUND_COLOR_REGEX
                : COLOR_REGEX,
              textFlowExtractor: extractTextOrBackgroundColorFromClassAttr,
            }
          : {
              getHex: (c: string) => allColors[c as ColorName],
              regex: isTextFlow ? TEXT_COLOR_CLASS_REGEX : COLOR_REGEX,
              textFlowExtractor: extractColorFromClassAttr,
            },
        {
          getHex: (h: string) => h,
          regex: isTextFlow ? TEXT_HEX_STYLE_REGEX : HEX_REGEX,
          textFlowExtractor: extractHexFromStyleAttr,
        },
      ];

      const defaultReplacer = (newColor: string) => () => newColor;

      const matchReplacer = (colorProp: ColorMatch | string) => {
        return isTextFlow
          ? () => {
              return typeof colorProp === 'string'
                ? wrapColorWithClassAttr(colorProp)
                : wrapColorWithClassAtributeByPropertyType(colorProp);
            }
          : defaultReplacer(colorProp as string);
      };

      matchToHexMappers.forEach(({ regex, getHex, textFlowExtractor }) => {
        const matches = _.uniq(
          getAllMatchesFromStyleProps(currentPathValue, regex),
        );

        matches.forEach((match: string) => {
          const colorMatch = isTextFlow ? textFlowExtractor(match) : match;
          // when merge se_newColorPaletteAdvancedWiringImprove' remove check for type;
          const colorNameFromMatch =
            typeof colorMatch === 'string' ? colorMatch : colorMatch.colorName;

          const colorFromMatchHex = getHex(colorNameFromMatch);

          const updateTheColorInStyleProps = (newColor: string) => {
            let valueToSet: ColorMatch | string = newColor;
            if (
              experiment.isOpen('se_newColorPaletteAdvancedWiringImprove') &&
              colorMatch instanceof ColorMatch
            ) {
              valueToSet = (
                colorMatch as ColorMatch
              ).setNewColorIndexFromColorName(newColor as ColorName);
            }
            currentPathValue = replaceColorsInStyleProps(
              currentPathValue,
              matchReplacer(valueToSet),
              match,
            );

            _.set(styleData, path, currentPathValue);
          };
          let colorToConnect;
          let priorityList = wiringPriorityList as unknown as COLOR_ROLES[];
          if (experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')) {
            if (isTextFlow) {
              // If there are no links in text so it should not be wired to links
              const hasLinks = styleData.linkList.length > 0;

              if (!hasLinks) {
                priorityList = priorityList.filter(
                  (v) => v !== COLOR_ROLES.LINK,
                );
              }
            }
          }

          const colorToWire = priorityList.find((role: COLOR_ROLES) => {
            return colorFromMatchHex === getColorValueByRole(allColors, role);
          }) as unknown as ColorName;

          if (colorToWire) {
            colorToConnect = getColorNameByRole(
              colorToWire as COLOR_ROLES,
            ) as string;
          } else {
            // if no match found in priority list, check theme colors
            colorToConnect = themeColors.find(
              (color) => allColors[color] === colorFromMatchHex,
            );
          }

          if (colorToConnect) {
            updateTheColorInStyleProps(colorToConnect as string);
          }
        });
      });
    },
  );

  if (styleData.type === 'ComponentStyle') {
    updateDataInPropertiesSource(styleData);
  }
};

/**
 * Performs advanced wiring for all components depending on their type and current hex color value
 */
export const runAutoWiring = async (editorAPI: EditorAPI) => {
  await editorAPI.documentServices.transactions.runAndWaitForApproval(
    async () => {
      const allComps = editorAPI.components.getAllComponents();
      const allColors = editorAPI.theme.colors.getAll();
      const themeColors = editorAPI.theme.colors.getVisibleThemeColorsKeys();

      const performWiring = (
        compType: CompType | typeof SITE_FONTS,
        compRef?: CompRef,
      ) => {
        const { getApi, pathPriorityLists } =
          COMPS_WIRING_CONFIG[compType as CompType];

        const styleApi = getApi(editorAPI);
        const styleData = compRef ? styleApi.get(compRef) : styleApi.getAll();

        if (compType === COMP_TYPES.SITE_BUTTON) {
          updateButtonsColorsInStyleData(
            styleData,
            allColors,
            pathPriorityLists as ButtonPathLists[],
          );
        } else {
          updateColorsInStyleData(
            styleData,
            pathPriorityLists as PathPriorityLists,
            allColors,
            themeColors,
          );
        }

        if (compRef) {
          styleApi.update(compRef, styleData);
        } else {
          styleApi.update(styleData);
        }
      };

      performWiring(SITE_FONTS);

      allComps.forEach((compRef: CompRef) => {
        const compType = editorAPI.components.getType(compRef) as CompType;

        if (COMPS_WIRING_CONFIG.hasOwnProperty(compType)) {
          performWiring(compType, compRef);
        }
      });

      await editorAPI.waitForChangesAppliedAsync();
    },
  );
};
