import constants from '@/constants';
import experiment from 'experiment';
import { COLOR_ROLES } from '../../../../colors';
import {
  buttonPathLists,
  type ButtonPathLists,
} from './getButtonPathPriorityLists';
import type { EditorAPI } from '@/editorAPI';

const { COMP_TYPES } = constants;

export type CompType = typeof COMP_TYPES[keyof typeof COMP_TYPES];

export type PathPriority = COLOR_ROLES[] | ((pathValue: any) => COLOR_ROLES[]);
export interface PathPriorityLists {
  [path: string]: PathPriority;
}

interface CompConfig {
  getApi: (editorAPI: EditorAPI) => any;
  pathPriorityLists: PathPriorityLists | ButtonPathLists[];
}

type CompsWiringConfig = Partial<
  Record<CompType | typeof SITE_FONTS, CompConfig>
>;

export const SITE_FONTS = 'siteFonts';
const HEADING_FONT_REGEX = /class="font_[0-6]"/;

const getStyleApi = () => ({
  getApi: (editorAPI: EditorAPI) => editorAPI.components.style,
});

export const WIRING_PRIORITY_LISTS = {
  HEADING: [
    COLOR_ROLES.TITLE,
    COLOR_ROLES.SUBTITLE,
    COLOR_ROLES.PRIMARY_TEXT,
    COLOR_ROLES.SECONDARY_TEXT,
    COLOR_ROLES.LINK,
  ],
  TEXT: [
    COLOR_ROLES.PRIMARY_TEXT,
    COLOR_ROLES.SECONDARY_TEXT,
    COLOR_ROLES.SUBTITLE,
    COLOR_ROLES.TITLE,
    COLOR_ROLES.LINK,
  ],
  MENU: [
    COLOR_ROLES.PRIMARY_TEXT,
    COLOR_ROLES.SECONDARY_TEXT,
    COLOR_ROLES.LINK,
  ],
  TEXT_HOVER: [COLOR_ROLES.LINK, COLOR_ROLES.SECONDARY_1, COLOR_ROLES.MAIN_1],

  BACKGROUND: [COLOR_ROLES.BACKGROUND, COLOR_ROLES.SECONDARY_BACKGROUND],
  LINKS: [COLOR_ROLES.LINK, COLOR_ROLES.SECONDARY_1],
  BORDER: [
    COLOR_ROLES.MAIN_1,
    COLOR_ROLES.SECONDARY_BACKGROUND,
    COLOR_ROLES.LINK,
  ],
  CONTAINER_BACKGROUND: [
    COLOR_ROLES.MAIN_1,
    COLOR_ROLES.BACKGROUND,
    COLOR_ROLES.SECONDARY_BACKGROUND,
  ],
};

const ROLES_WIRING_CONFIG = {
  PAGE: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.bg': WIRING_PRIORITY_LISTS.BACKGROUND,
    },
  },
  CONTAINER: {
    ...getStyleApi(),
    pathPriorityLists: {
      ...(experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')
        ? {
            'style.properties.bg': WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
            'style.properties.brd': WIRING_PRIORITY_LISTS.BORDER,
            'style.properties.bgctr':
              WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
          }
        : {
            'style.properties.bg': WIRING_PRIORITY_LISTS.BACKGROUND,
          }),
    },
  },
  DESIGN_DATA_BACKGROUND: {
    getApi: (editorAPI: EditorAPI) => editorAPI.components.design,
    pathPriorityLists: {
      ...(experiment.isOpen('se_newColorPaletteAdvancedWiringImprove')
        ? {
            background: WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
            shapeStyle: WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
            overrideColors: WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
          }
        : {
            background: WIRING_PRIORITY_LISTS.BACKGROUND,
            shapeStyle: WIRING_PRIORITY_LISTS.BACKGROUND,
            overrideColors: WIRING_PRIORITY_LISTS.BACKGROUND,
          }),
    },
  },
  LINE: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.brd': [COLOR_ROLES.LINE],
    },
  },
  VERTICAL_MENU: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.bg': WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.txt': WIRING_PRIORITY_LISTS.MENU,
      'style.properties.SKINS_bgSubmenu':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
    },
  },
  TEXT_INPUT: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.bg': WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.brd': WIRING_PRIORITY_LISTS.BORDER,
    },
  },
  TPA_SECTION: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.param_color_border-color':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_text-color-primar':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_text-color-secondary':
        WIRING_PRIORITY_LISTS.TEXT,
    },
  },

  TPA_WIDGET: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.param_color_arrowsColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_arrowsContainerBackgroundColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_bgColorExpand':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_customButtonBorderColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_customButtonColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_customButtonFontColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_externalCustomButtonBorderColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_externalCustomButtonColor': [
        COLOR_ROLES.LINK,
      ],
      'style.properties.param_color_foreColor': [COLOR_ROLES.SHADE_1],
      'style.properties.param_color_imageLoadingColor': [COLOR_ROLES.SHADE_2],
      'style.properties.param_color_itemBorderColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_itemDescriptionFontColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_itemDescriptionFontColorSlideshow':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_itemFontColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_itemFontColorSlideshow':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_itemIconColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_itemIconColorSlideshow':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_itemOpacity':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_itemShadowOpacityAndColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_loadMoreButtonBorderColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_loadMoreButtonColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_loadMoreButtonFontColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_overlayGradientColor1':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_overlayGradientColor2':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.param_color_textBoxBorderColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_textBoxFillColor': [COLOR_ROLES.SHADE_1],
      'style.properties.param_color_titleColorExpand':
        WIRING_PRIORITY_LISTS.TEXT,
    },
  },
  GLUED_CHAT: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.param_color_headerTextColor':
        WIRING_PRIORITY_LISTS.TEXT,
      'style.properties.param_color_primaryColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
    },
  },
  DROPDOWN_MENU: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.param_color_arrowsColor':
        WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
    },
  },
  FORM_CONTAINER: {
    ...getStyleApi(),
    pathPriorityLists: {
      'style.properties.bg': WIRING_PRIORITY_LISTS.CONTAINER_BACKGROUND,
      'style.properties.brd': WIRING_PRIORITY_LISTS.BORDER,
    },
  },
};

const COMPS_WIRING_CONFIG_DEFAULT: CompsWiringConfig = {
  [SITE_FONTS]: {
    getApi: (editorAPI) => editorAPI.theme.fonts,
    pathPriorityLists: {
      font_0: WIRING_PRIORITY_LISTS.HEADING,
      font_1: WIRING_PRIORITY_LISTS.HEADING,
      font_2: WIRING_PRIORITY_LISTS.HEADING,
      font_3: WIRING_PRIORITY_LISTS.HEADING,
      font_4: WIRING_PRIORITY_LISTS.HEADING,
      font_5: WIRING_PRIORITY_LISTS.HEADING,
      font_6: WIRING_PRIORITY_LISTS.HEADING,
      font_7: WIRING_PRIORITY_LISTS.TEXT,
      font_8: WIRING_PRIORITY_LISTS.TEXT,
      font_9: WIRING_PRIORITY_LISTS.TEXT,
      font_10: WIRING_PRIORITY_LISTS.TEXT,
    },
  },
  [COMP_TYPES.TEXT]: {
    getApi: (editorAPI) => editorAPI.components.data,
    pathPriorityLists: {
      text: (pathValue) => {
        return HEADING_FONT_REGEX.test(pathValue)
          ? WIRING_PRIORITY_LISTS.HEADING
          : WIRING_PRIORITY_LISTS.TEXT;
      },
    },
  },
  [COMP_TYPES.SITE_BUTTON]: {
    getApi: (editorAPI) => editorAPI.components.style,
    pathPriorityLists: buttonPathLists,
  },
  [COMP_TYPES.FIVE_GRID_LINE]: ROLES_WIRING_CONFIG.LINE,
  [COMP_TYPES.VERTICAL_LINE]: ROLES_WIRING_CONFIG.LINE,
  [COMP_TYPES.STRIP_COLUMNS_CONTAINER]:
    ROLES_WIRING_CONFIG.DESIGN_DATA_BACKGROUND,
  [COMP_TYPES.SECTION]: ROLES_WIRING_CONFIG.DESIGN_DATA_BACKGROUND,
  [COMP_TYPES.COLUMN]: ROLES_WIRING_CONFIG.DESIGN_DATA_BACKGROUND,
  [COMP_TYPES.CONTAINER]: ROLES_WIRING_CONFIG.CONTAINER,
  [COMP_TYPES.PAGE]: ROLES_WIRING_CONFIG.PAGE,
  [COMP_TYPES.HEADER]: ROLES_WIRING_CONFIG.CONTAINER,
  [COMP_TYPES.FOOTER]: ROLES_WIRING_CONFIG.CONTAINER,
};

const COMP_WIRING_CONFIG_UNDER_EXPERIMENT = {
  [COMP_TYPES.VERTICAL_MENU]: ROLES_WIRING_CONFIG.VERTICAL_MENU,
  [COMP_TYPES.STYLABLE_HORIZONTAL_MENU]: ROLES_WIRING_CONFIG.VERTICAL_MENU,
  [COMP_TYPES.FORM_TEXT_INPUT]: ROLES_WIRING_CONFIG.TEXT_INPUT,
  [COMP_TYPES.FORM_TEXT_AREA_INPUT]: ROLES_WIRING_CONFIG.TEXT_INPUT,
  [COMP_TYPES.TPA_SECTION]: ROLES_WIRING_CONFIG.TPA_SECTION,
  [COMP_TYPES.DROPDOWN_MENU]: ROLES_WIRING_CONFIG.DROPDOWN_MENU,
  [COMP_TYPES.GLUED_CHAT]: ROLES_WIRING_CONFIG.GLUED_CHAT,
  [COMP_TYPES.FORM_CONTAINER]: ROLES_WIRING_CONFIG.FORM_CONTAINER,
  [COMP_TYPES.SHAPE]: ROLES_WIRING_CONFIG.LINE,
};

export const COMPS_WIRING_CONFIG = experiment.isOpen(
  'se_newColorPaletteAdvancedWiringImprove',
)
  ? { ...COMPS_WIRING_CONFIG_DEFAULT, ...COMP_WIRING_CONFIG_UNDER_EXPERIMENT }
  : COMPS_WIRING_CONFIG_DEFAULT;
