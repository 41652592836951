import _ from 'lodash';
import experiment from 'experiment';
import {
  syncNewColorPaletteStart,
  syncNewColorPaletteFinish,
} from '@wix/bi-logger-editor/v2';
import { EditorParamsApiKey } from '@/apis';
import type { EditorAPI } from '@/editorAPI';

import { runTemplatesMigration } from './templatesMigration';
import { expandColorPalette } from './expandColorPalette';
import { INTERNAL_removeAllWiring } from './advancedWiring';
import { saveSiteMigratedFlag } from './utils';
import {
  isNewColorPaletteOpen,
  areColorsSynchronised,
} from '../colors/constants';
import { generalFlowFedops as fedops } from './fedops';
import { runSyncColorsMigration } from './syncColorsMigration';
import { biLogger } from '@/util';

export * from './advancedWiring';
export * from './scFlowMigration';

const newColorPaletteExperimentsOpened = () => {
  return (
    experiment.isOpen('se_colorPaletteRedesignOldSites') ||
    experiment.isOpen('se_colorPaletteReDesign')
  );
};

const setSiteMigratedFlag = (editorAPI: EditorAPI) => {
  fedops.setFlag.start();
  saveSiteMigratedFlag(editorAPI);
  fedops.setFlag.end();
};

export const runThemeMigrations = async (editorAPI: EditorAPI) => {
  const hasLinkedColors = !_.isEmpty(
    editorAPI.theme.colors.getAllLinkedColors(),
  );
  if (isNewColorPaletteOpen(editorAPI) && hasLinkedColors) return;

  if (
    experiment.isOpen('se_syncNewColorPalette') &&
    !newColorPaletteExperimentsOpened()
  ) {
    if (areColorsSynchronised(editorAPI)) {
      biLogger.report(syncNewColorPaletteStart({ action: 'skip' }));
      biLogger.report(syncNewColorPaletteFinish({ action: 'skip' }));
      return;
    }
    await runSyncColorsMigration(editorAPI);
    return;
  }

  const { siteCreationWizard, isInsideEditorX, isInsideAppStudio } =
    editorAPI.host.getAPI(EditorParamsApiKey);

  if (isInsideEditorX || isInsideAppStudio) {
    return;
  }

  const isNewSite = editorAPI.generalInfo.isDraft();

  if (experiment.isOpen('se_autoWireCompsColors')) {
    await expandColorPalette(editorAPI);
    await INTERNAL_removeAllWiring(editorAPI);
  }

  if (!isNewSite) {
    // For internal use
    if (experiment.isOpen('se_colorPaletteRedesignOldSites')) {
      await runTemplatesMigration(editorAPI);
      saveSiteMigratedFlag(editorAPI);
      return;
    }

    // This enables new UI for sites that have migrated but don't have flag set
    if (hasLinkedColors) {
      setSiteMigratedFlag(editorAPI);
    }
    return;
  }

  // This function runs earlier than SC finishes, so
  // migration for SC flow is triggered from ./packages/rEditor/rootComps/siteCreation/siteCreationPagesViewMapper.ts
  if (siteCreationWizard) {
    return;
  }

  // For cases when user creates a site from an already migrated template
  if (hasLinkedColors) {
    setSiteMigratedFlag(editorAPI);
    return;
  }

  if (experiment.isOpen('se_colorPaletteReDesign')) {
    await runTemplatesMigration(editorAPI);
  }
};
