/* eslint-disable you-dont-need-lodash-underscore/reduce */
import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';

import {
  COLOR_ROLES,
  ADVANCED_COLORS,
  NEW_PALETTE_COLORS,
  THEME_COLORS,
  OLD_PALETTE_COLORS,
  EMPTY_COLOR_NAME_VALUE,
  ALL_ACCENTS_COLORS,
  TEXT_COLOR_CLASS_REGEX,
  COLOR_REGEX,
  PRESETS,
} from './constants';
import type { ColorName, ColorPalette, LinkedColors } from './types';
import {
  extractColorFromClassAttr,
  getColorNameByRole,
  isTextCompData,
} from './utils';
import type { CompRef } from '@wix/document-services-types';
import {
  replaceColorsInStyleProps,
  wrapColorWithClassAttr,
} from '../migrations/advancedWiring/utils';
import experiment from 'experiment';
import { isNewColorPaletteOpen } from './constants';

export function createColorsApi(editorAPI: EditorAPI) {
  const isThemeColor = (
    maybeColorName: ColorName | string,
  ): maybeColorName is ColorName => {
    return maybeColorName.includes('color');
  };

  /** onlyNew will return colors 11-15, 18, 36-65
   * no argument will return 0-65
   */
  const getAll = (filterQuery?: 'onlyNew' | 'onlyOld'): ColorPalette => {
    const queryMap = {
      onlyNew: NEW_PALETTE_COLORS,
      onlyOld: OLD_PALETTE_COLORS,
    };
    const themeColors = editorAPI.dsRead.theme.colors.getAll();

    const pickBy = queryMap[filterQuery];
    return pickBy ? _.pick(themeColors, pickBy) : themeColors;
  };

  const findLinkedColors = (
    colorName: ColorName,
    linkedColors: LinkedColors | undefined,
  ): ColorName[] => {
    const colorNamesToUpdate: ColorName[] = [];
    if (!linkedColors) {
      return colorNamesToUpdate;
    }
    _.forOwn(
      linkedColors,
      (value: ColorName | typeof EMPTY_COLOR_NAME_VALUE, key: string) => {
        if (value === colorName) {
          colorNamesToUpdate.push(key as ColorName);
        }
      },
    );

    return colorNamesToUpdate;
  };

  const isLink = (color: ColorName) =>
    isThemeColor(color) || color === EMPTY_COLOR_NAME_VALUE;

  const isAccentLinkedToThemeColor = (
    colorName: ColorName,
    maybeColorName: ColorName | string,
  ) => {
    return isAccentColor(colorName) && isThemeColor(maybeColorName);
  };

  const updateDependantColorNamesInComponents = async (
    editorAPI: EditorAPI,
    onlyAccentsToUpdate: ColorPalette,
  ) => {
    const allComps: CompRef[] = editorAPI.components.getAllComponents();

    const replaceColors = (compRef: CompRef) => {
      for (const compFunc of PRESETS) {
        const compApi = compFunc.getApi(editorAPI);
        const compData = compApi.get(compRef);
        let shouldUpdateComp = false;

        if (_.isEmpty(compData)) {
          continue;
        }
        compFunc.colorPaths.forEach((colorPath) => {
          const pathValue = _.get(compData, colorPath);

          if (!pathValue) return;

          let shouldUpdateField = false;
          shouldUpdateComp = false;

          const isTextFlow = isTextCompData(compData);

          const replacer = (colorMatch: string) => {
            const cleanColorName = isTextFlow
              ? extractColorFromClassAttr(colorMatch)
              : colorMatch;

            // {[cleanColorName]: newColorName} if there is no such color in accentsToWire it should return old value
            const colorToUpdate =
              onlyAccentsToUpdate[cleanColorName as ColorName] ||
              cleanColorName;

            shouldUpdateField = true;
            return isTextFlow
              ? wrapColorWithClassAttr(colorToUpdate)
              : colorToUpdate;
          };

          const newStyleProps = replaceColorsInStyleProps(
            pathValue,
            replacer,
            isTextFlow ? TEXT_COLOR_CLASS_REGEX : COLOR_REGEX,
          );

          if (shouldUpdateField) {
            _.set(compData, colorPath, newStyleProps);

            shouldUpdateComp = true;
          }
        });

        if (shouldUpdateComp) {
          compApi.update(compRef, compData);
        }
      }
    };

    allComps.forEach((compRef: CompRef) => {
      replaceColors(compRef);
    });

    await editorAPI.waitForChangesAppliedAsync();
  };

  /**
   * Update or link colors.
   * @param colorsToUpdate color mapping object. Key is a ColorName,
   * value can be hex, ColorName (to link and update), or EMPTY_COLOR_NAME_VALUE
   */
  const update = async (colorsToUpdate: ColorPalette) => {
    const currentPalette = getAll();
    const currentLinkedColors = getAllLinkedColors();
    const hexToUpdate: ColorPalette = _.omitBy(colorsToUpdate, isLink);
    const linksToUpdate: LinkedColors = _.pickBy(colorsToUpdate, isLink);

    // Flatten all the links
    // E.g. we have 11 linked to 42. Now we want to link 42 to 41. We have to relink 11 to 41 as well
    const dependentLinksToUpdate = _.reduce(
      linksToUpdate,
      (acc, colorNameToLinkTo, colorNameToUpdate) => {
        if (
          colorNameToLinkTo === EMPTY_COLOR_NAME_VALUE &&
          !isAccentColor(colorNameToUpdate as ColorName)
        )
          return acc;

        const linked: ColorName[] = findLinkedColors(
          colorNameToUpdate as ColorName,
          currentLinkedColors,
        );
        linked.forEach((color) => (acc[color] = colorNameToLinkTo));
        return acc;
      },
      {} as LinkedColors,
    );
    const allLinks = {
      ...currentLinkedColors,
      ...linksToUpdate,
      ...dependentLinksToUpdate,
    };
    const allLinksToUpdate = experiment.isOpen(
      'se_fixDuplicatesColorsInNewColorPalette',
    )
      ? _.omitBy(allLinks, (_v, k) => {
          // We should remove from links to update colors that exists in hexToUpdate as well in linksToUpdate, it
          // covers case when set new palette object with linked colors and same values as new one colors
          // to update {'color_41': 'SOME HEX', color_42: 'SOME HEX'} current linked colors {color_42: 'color_41'}
          // Example theme update https://jira.wixpress.com/browse/WEED-29988 see api.unit.ts `should update accent colors by provide in colors to update if all accents linked to one`
          return hexToUpdate[k as ColorName];
        })
      : allLinks;

    // Update hex of colors linked to colorNameToUpdate
    // E.g. we have 11 linked to 36 and we want to change 36 to #fff.
    // Now, we need to update 11 to be #fff as well
    const dependentHexToUpdate = _.reduce(
      hexToUpdate,
      (acc, hex, colorNameToUpdate) => {
        const linked = findLinkedColors(
          colorNameToUpdate as ColorName,
          allLinksToUpdate,
        );
        linked.forEach((color) => {
          acc[color] = hex;
        });

        // E.g. given linked colors { color_42: color_41 },
        // we call update with { color_42: #fff }.
        // Then, we need to delete 42 to 41 link, because 42 is no more dependent on 41
        if (isAccentColor(colorNameToUpdate as ColorName)) {
          delete allLinksToUpdate[colorNameToUpdate as ColorName];
        }
        return acc;
      },
      {} as ColorPalette,
    );

    const hexUpdates = {
      ...hexToUpdate,
      ...dependentHexToUpdate,
    };

    // Update hex of colors that we update the link of
    // E.g. we have { color_11: color_37 }, so we change hex of 11 to the same as 37
    const linkedHexUpdates = _.reduce(
      { ...linksToUpdate, ...dependentLinksToUpdate },
      (acc, colorLinkedTo, colorNameToUpdate) => {
        if (colorLinkedTo === EMPTY_COLOR_NAME_VALUE) return acc;

        acc[colorNameToUpdate as ColorName] =
          hexUpdates[colorLinkedTo as ColorName] ??
          currentPalette[colorLinkedTo as ColorName];
        return acc;
      },
      {} as ColorPalette,
    );

    linkColors(allLinksToUpdate);

    editorAPI.dsActions.theme.colors.update({
      ...hexUpdates,
      ...linkedHexUpdates,
    });
    if (
      isNewColorPaletteOpen(editorAPI) &&
      experiment.isOpen('se_newColorPaletteUpdateComponentsOnReplaceColors')
    ) {
      const onlyAccentColorsToUpdate = _.pickBy(colorsToUpdate, (v, k) =>
        isAccentLinkedToThemeColor(k as ColorName, v),
      );

      if (!_.isEmpty(onlyAccentColorsToUpdate)) {
        // Updates color name in component structures
        await updateDependantColorNamesInComponents(
          editorAPI,
          onlyAccentColorsToUpdate,
        );

        // after success updating components we need to link old unused accent
        // to __empty__ because we don't need it enymore
        const getAccentLinksToUpdate = (
          colorsToUpdate: ColorPalette,
        ): LinkedColors => {
          const linksToUpdate = _.pickBy(colorsToUpdate, isLink);

          return _.mapValues(
            linksToUpdate,
            (value: ColorName, key: ColorName) => {
              return isAccentLinkedToThemeColor(key, value)
                ? EMPTY_COLOR_NAME_VALUE
                : value;
            },
          );
        };
        // {...existingLinks, [oldAccent]: '__empty__'}
        const allLinkedColors = {
          ...allLinksToUpdate,
          ...getAccentLinksToUpdate(onlyAccentColorsToUpdate),
        };
        linkColors(allLinkedColors);
      }
    }
  };

  /**
   *
   * @param color ColorName|string can be color_* or HEX/RGB color directly
   * @returns
   */
  const get = (color: ColorName | string) => {
    if (isThemeColor(color)) {
      return editorAPI.dsRead.theme.colors.get(color as ColorName);
    }
    return color;
  };

  /**
   * @param linkedColors - all linked colors need to be provided
   */
  const linkColors = (linkedColors: LinkedColors) => {
    // @ts-expect-error
    editorAPI.site.features.update('themeConfig', {
      colorMapping: linkedColors,
    });
  };

  const getAllLinkedColors = (): LinkedColors | undefined => {
    // @ts-expect-error
    const feature = editorAPI.site.features.get('themeConfig') as {
      colorMapping: Record<ColorName, ColorName>;
    };

    return feature?.colorMapping;
  };

  const isAccentColorEmpty = (colorName: ColorName) => {
    if (!isAccentColor(colorName)) return false;
    const allLinkedColors = getAllLinkedColors();

    return (
      allLinkedColors?.[colorName] === EMPTY_COLOR_NAME_VALUE ||
      NEW_PALETTE_COLORS.includes(allLinkedColors?.[colorName] as ColorName)
    );
  };

  const getReplacedAccentColor = (colorName: ColorName) => {
    if (!isAccentColor(colorName)) return colorName;

    const allLinkedColors = getAllLinkedColors();

    return allLinkedColors?.[colorName];
  };

  const getVisibleThemeColorsKeys = () =>
    THEME_COLORS.filter((color) => !isAccentColorEmpty(color));

  /**
   * Colors can be hidden in ui if it's linked to other one
   */
  const getVisibleThemeColors = (): ColorPalette => {
    const visibleColors = getVisibleThemeColorsKeys();
    return _.pick(getAll('onlyNew'), visibleColors);
  };

  // colorDefaults and advancedColors is the same thing
  const getAdvancedColors = () => _.pick(getAll('onlyNew'), ADVANCED_COLORS);

  const isBaseColor = (colorName: ColorName): boolean => {
    const baseColors = [
      getColorNameByRole(COLOR_ROLES.MAIN_1),
      getColorNameByRole(COLOR_ROLES.MAIN_2),
    ];

    return baseColors.includes(colorName);
  };

  const isAccentColor = (colorName: ColorName): boolean => {
    return ALL_ACCENTS_COLORS.includes(colorName);
  };

  return {
    get,
    getAll,
    getVisibleThemeColorsKeys,
    getVisibleThemeColors,
    getAdvancedColors,
    getAllLinkedColors,
    isAccentColorEmpty,
    getReplacedAccentColor,
    update,
    isBaseColor,
    isAccentColor,
    findLinkedColors,
  };
}
